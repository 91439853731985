"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getRandomFunctions;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
function getRandomFunctions() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var randomFunction = p.randomFunction;
  function customRandom() {
    if (typeof randomFunction === "function") {
      return randomFunction();
    }
    throw "[APP] Missing custom random function";
  }
  function generatePropsArray(_ref) {
    var props = _ref.props,
      length = _ref.length;
    var propsLength = props.reduce(function (cL, _ref2) {
      var n = _ref2.n;
      return n ? cL + n : cL;
    }, 0);
    var computedColorsLength = props.reduce(function (cL, _ref3) {
      var n = _ref3.n;
      return !n ? cL + 1 : cL;
    }, 0);
    var propsResidual = length - propsLength;
    var computedLength = propsResidual > 0 && computedColorsLength > 0 ? Math.floor(propsResidual / computedColorsLength) : 0;
    if (computedColorsLength * computedLength + propsLength < length) {
      computedLength = computedLength + 1;
    }
    return _shuffleArray((0, _toConsumableArray2["default"])(props.reduce(function (a, _ref4) {
      var n = _ref4.n,
        d = _ref4.d;
      var l = n || computedLength;
      if (l > 0) {
        a.push.apply(a, (0, _toConsumableArray2["default"])((0, _toConsumableArray2["default"])(Array(l)).map(function () {
          return d;
        })));
      }
      return a;
    }, [])));
  }
  function _shuffleArray(array, externalRandom) {
    var currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      var rF = typeof randomFunction === "function" ? customRandom : Math.random;
      randomIndex = typeof externalRandom === "function" ? Math.floor(externalRandom(rF) * currentIndex) : Math.floor(rF() * currentIndex);
      currentIndex--;
      var _ref5 = [array[randomIndex], array[currentIndex]];
      array[currentIndex] = _ref5[0];
      array[randomIndex] = _ref5[1];
    }
    return array;
  }
  function _getRandomInt(min, max, externalRandom) {
    min = Math.ceil(min);
    max = Math.floor(max);
    var rF = typeof randomFunction === "function" ? customRandom : Math.random;
    if (typeof externalRandom === "function") {
      return Math.floor(externalRandom(rF) * (max - min + 1)) + min;
    }
    return Math.floor(rF() * (max - min + 1)) + min;
  }
  function getThreeJsInternalRandomFunctions() {
    var randomVariables = [];
    var randomI = -1;
    var externalRandom;
    function getExternalRandom() {
      return randomVariables.length >= 2 ? function (defaultRandom) {
        randomI = randomI + 1;
        if (randomVariables.length <= randomI + 1) {
          randomI = 0;
        }
        if (typeof randomVariables[randomI] === "number") {
          var r = randomVariables[randomI];
          if (r >= 0 && r <= 1) {
            return r;
          }
        }
        return defaultRandom();
      } : null;
    }
    function setRandomVariables(_ref6) {
      var threeJs = _ref6.threeJs;
      randomVariables = threeJs.randomVariables || [];
      randomI = -1;
      externalRandom = getExternalRandom();
    }
    return {
      shuffleArray: function shuffleArray(array) {
        return _shuffleArray(array, externalRandom);
      },
      getRandomInt: function getRandomInt(min, max) {
        return _getRandomInt(min, max, externalRandom);
      },
      setRandomVariables: setRandomVariables
    };
  }
  return {
    getRandomInt: _getRandomInt,
    shuffleArray: _shuffleArray,
    generatePropsArray: generatePropsArray,
    getThreeJsInternalRandomFunctions: getThreeJsInternalRandomFunctions
  };
}