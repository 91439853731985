"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getSelectObjectFunction;
function getSelectObjectFunction() {
  function selectObject(threeJs, object, removePrevious) {
    if (removePrevious && threeJs.selectedObject && threeJs.selectedObject !== object) {
      if (threeJs.selectedObject) {
        resetOpacity({
          child: threeJs.selectedObject
        });
      }
      threeJs.selectedObject.userData.isActive = false;
      threeJs.selectedObject = null;
    }
    if (object) {
      var isActive = object.userData.isActive;
      if (isActive) {
        resetOpacity({
          child: object
        });
        object.userData.isActive = false;
      } else {
        setOpacity({
          child: object,
          newOpacity: 0.3
        });
        object.userData.isActive = true;
      }
      if (object.userData.isActive) {
        threeJs.selectedObject = object;
      } else {
        threeJs.selectedObject = null;
      }
    }
  }
  function setOpacity(_ref) {
    var child = _ref.child,
      newOpacity = _ref.newOpacity;
    child.traverse(function (c) {
      if (c !== null && c !== void 0 && c.material) {
        if (typeof c.userData.tempOpacity === "undefined") {
          c.userData.tempOpacity = typeof c.material.opacity === "number" ? c.material.opacity : 1;
        }
      }
    });
    child.traverse(function (c) {
      if (c !== null && c !== void 0 && c.material) {
        var tempOpacity = c.userData.tempOpacity;
        c.material.opacity = newOpacity < 0 ? 0 : newOpacity > tempOpacity ? tempOpacity : newOpacity;
        c.material.transparent = true;
        c.material.needsUpdate = true;
      }
    });
  }
  function resetOpacity(_ref2) {
    var child = _ref2.child;
    child.traverse(function (c) {
      if (c !== null && c !== void 0 && c.material && typeof c.userData.tempOpacity === "number") {
        c.material.opacity = c.userData.tempOpacity;
        c.needsUpdate = true;
      }
    });
  }
  return {
    selectObject: selectObject,
    setOpacity: setOpacity,
    resetOpacity: resetOpacity
  };
}