"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = canvasTextRenderer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
function canvasTextRenderer() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var threeJs = props.threeJs,
    _props$toConsole = props.toConsole,
    toConsole = _props$toConsole === void 0 ? true : _props$toConsole,
    _props$luminanceType = props.luminanceType,
    luminanceType = _props$luminanceType === void 0 ? 0 : _props$luminanceType,
    _props$charsetType = props.charsetType,
    charsetType = _props$charsetType === void 0 ? 0 : _props$charsetType,
    _props$contrast = props.contrast,
    contrast = _props$contrast === void 0 ? 1.4 : _props$contrast,
    _props$resolution = props.resolution,
    resolution = _props$resolution === void 0 ? 160 : _props$resolution;
  if (threeJs.renderType !== "SVG") {
    var splitScreen = threeJs.splitScreen;
    var currentComposition = threeJs.currentComposition;
    var parent = threeJs.parentContainer;
    var width = parent.offsetWidth;
    var height = parent.offsetHeight;
    var tempEnableAnimation = threeJs.enableAnimation;
    threeJs.enableAnimation = false;
    var tempResizeFunction = threeJs.onResize;
    threeJs.onResize = function () {};
    tempResizeFunction();
    var saveCanvas = document.createElement("canvas");
    saveCanvas.setAttribute("width", width);
    saveCanvas.setAttribute("height", height);
    var context = saveCanvas.getContext("2d");
    var bgString = currentComposition.background || "#eeac56";
    context.fillStyle = bgString;
    if (bgString.match("linear-gradient")) {
      try {
        var colors = bgString.match(/((rgba?\((.*?)\))|#[a-zA-Z0-9]+)/g);
        var gradient = context.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, colors[0]);
        gradient.addColorStop(1, colors[1]);
        context.fillStyle = gradient;
      } catch (e) {
        context.fillStyle = bgString;
        console.log(e);
      }
    }
    context.fillRect(0, 0, width, height);
    context.fillStyle = "#000000";
    if (bgString.match("radial-gradient")) {
      try {
        var _colors = bgString.match(/((rgba?\((.*?)\))|#[a-zA-Z0-9]+)/g);
        var tempCanvas = document.createElement("canvas");
        tempCanvas.setAttribute("width", width);
        tempCanvas.setAttribute("height", height);
        var tctx = tempCanvas.getContext("2d");
        var shittX = Math.max(width, height) / 2;
        var shittY = Math.max(width, height) / 2;
        var _gradient = tctx.createRadialGradient(width / 2 + shittX, height / 2 + shittY, 1, width / 2 + shittX, height / 2 + shittY, Math.max(width / 2, height / 2) * 1.45);
        _gradient.addColorStop(0, _colors[0]);
        _gradient.addColorStop(1, _colors[1]);
        var scaleX = height > width ? width / height : 1;
        var scaleY = width > height ? height / width : 1;
        tctx.scale(scaleX, scaleY);
        tctx.translate(-shittX * scaleX, -shittY * scaleY);
        tctx.fillStyle = _gradient;
        tctx.fillRect(width / 2, height / 2, Math.max(width, height), Math.max(width, height));
        tctx.setTransform(1, 0, 0, 1, 0, 0);
        context.drawImage(tempCanvas, 0, 0);
      } catch (e) {
        context.fillStyle = bgString;
        context.fillRect(0, 0, width, height);
        context.fillStyle = "#000000";
        console.log(e);
      }
    }
    var _threeJs$postFilters = threeJs.postFilters,
      postFilters = _threeJs$postFilters === void 0 ? [] : _threeJs$postFilters;
    var filterString = postFilters && postFilters.map(function (postFilter) {
      return postFilter.type + "(" + postFilter.value + ")";
    }).join(" ") || "";
    if (splitScreen) {
      var _threeJs$composer, _threeJs$composer$ren, _threeJs$composer2, _threeJs$composer3, _threeJs$composer3$re, _threeJs$splitScreenD5;
      var backgroundChildren = (0, _toConsumableArray2["default"])(threeJs.canvasBackgroundContainer.children);
      backgroundChildren.map(function (canvas, i) {
        var n = i === 1 ? 0 : i;
        var _ref = i === 0 ? {
            visibility: "visible"
          } : threeJs.getVisibility(n),
          visibility = _ref.visibility;
        if (visibility === "hidden") {
          return;
        }
        var _ref2 = i === 0 ? {
            left: 0,
            top: 0
          } : threeJs.getLeftAndTop(n, p),
          left = _ref2.left,
          top = _ref2.top;
        var childDim = i === 0 ? {
          width: 0,
          height: 0
        } : threeJs.getWidthAndHeight(n, p);
        var x = left * width / 100 - childDim.width / 2;
        var y = top * height / 100 - childDim.height / 2;
        context.drawImage(canvas, x, y);
      });
      var effectChildren = (0, _toConsumableArray2["default"])(threeJs.effectsContainer.children);
      var rendererOpacity = typeof ((_threeJs$composer = threeJs.composer) === null || _threeJs$composer === void 0 ? void 0 : (_threeJs$composer$ren = _threeJs$composer.renderer) === null || _threeJs$composer$ren === void 0 ? void 0 : _threeJs$composer$ren.opacity) === "number" ? threeJs.composer.renderer.opacity : 1;
      var blendMode = (_threeJs$composer2 = threeJs.composer) !== null && _threeJs$composer2 !== void 0 && _threeJs$composer2.enable && typeof ((_threeJs$composer3 = threeJs.composer) === null || _threeJs$composer3 === void 0 ? void 0 : (_threeJs$composer3$re = _threeJs$composer3.renderer) === null || _threeJs$composer3$re === void 0 ? void 0 : _threeJs$composer3$re.blendMode) === "string" ? threeJs.composer.renderer.blendMode : "";
      var na = [2, 3, 4, 0];
      na.forEach(function (n) {
        var rendererPropName = n ? "renderer" + n : "renderer";
        if (threeJs[rendererPropName]) {
          var _threeJs$splitScreenD, _threeJs$splitScreenD2, _threeJs$splitScreenD3, _threeJs$splitScreenD4;
          var _threeJs$getVisibilit = threeJs.getVisibility(n),
            visibility = _threeJs$getVisibilit.visibility;
          if (visibility === "hidden") {
            return;
          }
          var splitScreenCustomPostFilters = (splitScreen || n > 0) && (_threeJs$splitScreenD = threeJs.splitScreenData) !== null && _threeJs$splitScreenD !== void 0 && _threeJs$splitScreenD.renderersData && (_threeJs$splitScreenD2 = threeJs.splitScreenData) !== null && _threeJs$splitScreenD2 !== void 0 && _threeJs$splitScreenD2.renderersData["" + n] && (_threeJs$splitScreenD3 = threeJs.splitScreenData) !== null && _threeJs$splitScreenD3 !== void 0 && _threeJs$splitScreenD3.renderersData["" + n].postFilters ? (_threeJs$splitScreenD4 = threeJs.splitScreenData) === null || _threeJs$splitScreenD4 === void 0 ? void 0 : _threeJs$splitScreenD4.renderersData["" + n].postFilters : null;
          var splitScreenCustomPostFilterString = splitScreenCustomPostFilters && splitScreenCustomPostFilters.map(function (postFilter) {
            return postFilter.type + "(" + postFilter.value + ")";
          }).join(" ") || "";
          var fS = !splitScreen ? filterString : splitScreenCustomPostFilterString;
          var _threeJs$getLeftAndTo = threeJs.getLeftAndTop(n),
            left = _threeJs$getLeftAndTo.left,
            top = _threeJs$getLeftAndTo.top;
          var childDim = threeJs.getWidthAndHeight(n);
          var x = left * width / 100 - childDim.width / 2;
          var y = top * height / 100 - childDim.height / 2;
          var effectCanvas = effectChildren[!n ? n : n - 1];
          if (effectCanvas) {
            if (fS) {
              context.filter = fS;
            }
            context.drawImage(effectCanvas, x, y);
            context.filter = "none";
          }
          if (blendMode) {
            context.globalCompositeOperation = blendMode;
          }
          context.globalAlpha = rendererOpacity;
          var canvas = threeJs[rendererPropName].domElement;
          if (canvas) {
            if (fS) {
              context.filter = fS;
            }
            context.drawImage(canvas, x, y);
            context.filter = "none";
          }
          context.globalAlpha = 1;
          context.globalCompositeOperation = "source-over";
        }
      });
      context.globalAlpha = 1;
      context.globalCompositeOperation = "source-over";
      var borderStyle = splitScreen && (_threeJs$splitScreenD5 = threeJs.splitScreenData) !== null && _threeJs$splitScreenD5 !== void 0 && _threeJs$splitScreenD5.hideBorders ? "none" : "";
      if (borderStyle !== "none") {
        var containerHeight = height > width ? width : height;
        var startY = containerHeight !== height ? (height - containerHeight) / 2 : 0;
        var endY = containerHeight !== height ? startY + containerHeight : height;
        var dimFloorHundred = Math.max(600, Math.min(Math.floor(width / 100) * 100, Math.floor(height / 100) * 100));
        var lineWidth = Math.floor(dimFloorHundred / 600 * 10) / 10;
        context.beginPath();
        context.lineWidth = lineWidth;
        context.strokeStyle = "rgba(0,0,0,0.2)";
        context.moveTo(width / 2, startY);
        context.lineTo(width / 2, endY);
        context.stroke();
        context.beginPath();
        context.lineWidth = lineWidth;
        context.strokeStyle = "rgba(0,0,0,0.2)";
        context.moveTo(0, height / 2);
        context.lineTo(width, height / 2);
        context.stroke();
      }
    } else {
      var _threeJs$composer4, _threeJs$composer4$re, _threeJs$composer5, _threeJs$composer6, _threeJs$composer6$re;
      var backgroundFullCanvas = threeJs.canvasBackgroundContainer.children[0];
      var backgroundCanvas = threeJs.canvasBackgroundContainer.children[1];
      var effect = threeJs.effectsContainer.firstChild;
      var renderer = threeJs.renderer.domElement;
      var _rendererOpacity = typeof ((_threeJs$composer4 = threeJs.composer) === null || _threeJs$composer4 === void 0 ? void 0 : (_threeJs$composer4$re = _threeJs$composer4.renderer) === null || _threeJs$composer4$re === void 0 ? void 0 : _threeJs$composer4$re.opacity) === "number" ? threeJs.composer.renderer.opacity : 1;
      var _blendMode = (_threeJs$composer5 = threeJs.composer) !== null && _threeJs$composer5 !== void 0 && _threeJs$composer5.enable && typeof ((_threeJs$composer6 = threeJs.composer) === null || _threeJs$composer6 === void 0 ? void 0 : (_threeJs$composer6$re = _threeJs$composer6.renderer) === null || _threeJs$composer6$re === void 0 ? void 0 : _threeJs$composer6$re.blendMode) === "string" ? threeJs.composer.renderer.blendMode : "";
      var _startY = width / height < 1 ? (height - width) / 2 : 0;
      context.drawImage(backgroundFullCanvas, 0, 0);
      context.drawImage(backgroundCanvas, 0, _startY);
      if (filterString) {
        context.filter = filterString;
      }
      context.drawImage(effect, 0, _startY);
      context.filter = "none";
      if (_blendMode) {
        context.globalCompositeOperation = _blendMode;
      }
      context.globalAlpha = _rendererOpacity;
      if (filterString) {
        context.filter = filterString;
      }
      context.drawImage(renderer, 0, _startY);
      context.filter = "none";
    }
    var gWidth = height > resolution ? Math.floor(Math.floor(2.2 * width) * resolution / height) : width > resolution ? resolution : width;
    var gHeight = height > resolution ? resolution : width > resolution ? Math.floor(height * resolution / Math.floor(2.2 * width)) : height;
    var grayCanvas = document.createElement("canvas");
    grayCanvas.setAttribute("width", gWidth);
    grayCanvas.setAttribute("height", gHeight);
    var grayContext = grayCanvas.getContext("2d");
    grayContext.drawImage(saveCanvas, 0, 0, gWidth, gHeight);
    var imageData = grayContext.getImageData(0, 0, gWidth, gHeight);
    var ls = [];
    var calcLuminanceFunctions = [function (r, g, b) {
      return r * .299 + g * .587 + b * .114;
    }, function (r, g, b) {
      return r * 0.2126 + g * 0.715 + b * 0.0722;
    }];
    var luminanceFunction = calcLuminanceFunctions[luminanceType] || calcLuminanceFunctions[0];
    var intercept = 128 * (1 - (contrast + 1));
    for (var i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = imageData.data[i] * (contrast + 1) + intercept;
      imageData.data[i + 1] = imageData.data[i + 1] * (contrast + 1) + intercept;
      imageData.data[i + 2] = imageData.data[i + 2] * (contrast + 1) + intercept;
      ls.push(luminanceFunction(imageData.data[i], imageData.data[i + 1], imageData.data[i + 2]));
    }
    var charsSet = ['`.-\':_,^=;><+!rc*/z?sLTv)J7(|Fi{C}fI31tlu[neoZ5Yxjya]2ESwqkP6h9d4VpOGbUAKXHm8RD#$Bg0MNWQ%&@'.split("").reverse().join(""), '$@B%8&WM#*oahkbdpqwmZO0QLCJUYXzcvunxrjft/\\|()1{}[]?-_+~<>i!lI;:,"^`\'.'];
    var chars = charsSet[charsetType] || charsSet[0];
    var text = ls.reduce(function (text, luminance, i) {
      return text + chars[Math.ceil((chars.length - 1) * luminance / 255)] + ((i + 1) % gWidth === 0 ? '\n' : '');
    }, '');
    if (tempEnableAnimation) {
      threeJs.enableAnimation = true;
    }
    if (threeJs.render) {
      threeJs.render();
    }
    threeJs.onResize = tempResizeFunction;
    context.clearRect(0, 0, saveCanvas.width, saveCanvas.height);
    grayContext.clearRect(0, 0, grayCanvas.width, grayCanvas.height);
    if (toConsole) {
      console.clear();
      console.log(text);
    } else {
      return text;
    }
  }
}